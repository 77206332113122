import React from "react";
import PropTypes from "prop-types";

//Context imports
import UserContext from "../../context/UserContext.js";

//Design imports
import { Container, Grid } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";


const styles = () =>({
    containerWrapper: {
        position: "relative",
    },
    mainPageContainer: {
        paddingLeft: "8px",
        marginTop: "17px",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "20"
    },
    mapHolder: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "10"
    },
    logo: {
        height: "41px",
        width: "41px"
    },
    fleetListComponent: {
        position: "fixed",
        bottom: "32px",
        right: "51px",
        width: "248px"
    }
});

const Main = (props) => {
    const userContext = React.useContext(UserContext);

    return(
        <React.Fragment>
            <div className={props.classes.containerWrapper}>
                <div className={props.classes.mainPageContainer}>
                </div>
            </div>
        </React.Fragment>
    );
}

Main.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default withStyles(styles)(Main);
