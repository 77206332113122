import Cookies from 'universal-cookie';
import {COOKIE_AUTH_KEY} from '../../constants/index.js';

export const getAuthHeader = () => {
    let cookies = new Cookies();
    let authToken = cookies.get(COOKIE_AUTH_KEY);

    return {'Authorization' : `Bearer ${authToken}`};
}

export const getAuthToken = () => {
    let cookies = new Cookies();
    let authToken = cookies.get(COOKIE_AUTH_KEY);

    return authToken;
}