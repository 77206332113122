import axiosBase from "axios";

export const BACKEND_URL = process.env.BACKEND_URL;
export const API_URL = process.env.API_URL;
export const AUTHAPI_URL = process.env.AUTHAPI_URL;

//export const API_URL = "http://localhost:3000"

// export const urlBase = window.location.hostname.includes("localhost")
//   ? "http://localhost:3000"
//   : `https://${window.location.host}`;

//orgil added
////export const urlBase = "https://staging-api-toyobijin.azurewebsites.net";
//export const urlBAse = process.env.API_URL;
//console.log(urlBAse);
//export const urlBase = "https://assist-jrcs.com";

export const axios = axiosBase.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  },
  responseType: "json",
});

export const axios_authapi = axiosBase.create({
  baseURL: `${AUTHAPI_URL}/api/auth`,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  },
  responseType: "json",
});
