import { axios_authapi } from "../constants/request";
import {getAuthToken} from "./common/session.js";

export const LoginOid = async(oid) => {
  try {
    let token = await axios_authapi.post("/login/oid", {
      oid: oid
    });
    return {isLoginSuccess: true, token: token.data}       
  }catch(e) {
    return {isLoginSuccess: false}
  }
}

export const LoginValidateToken = async() => {
  let cookieToken = getAuthToken();

  try {
    let {data} = await axios_authapi.post("/login/validateToken", {
      token: cookieToken
    });
    
    return {isValid: true, userData: data}
  }catch(e) {
    return {isValid: false}
  }
}