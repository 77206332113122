import React from "react"
import PropTypes from "prop-types";
import {Container} from "@material-ui/core";


const FullScreenLayout = (props) => {
    return (
        <React.Fragment>
            <props.component />
        </React.Fragment>
    )
}

FullScreenLayout.propTypes = {
    name: PropTypes.string
};

export default FullScreenLayout;