import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Routes } from "react-router-dom";
import {Provider} from "react-redux";

//Context
import {useLocalStorage} from './context/storage/useContextStorage.js';
import UserContext from './context/UserContext.js'
import store from "./model/store/store.js";
import Favicon from "react-favicon";

//Layout imports
import FullScreenLayout from "./layout/fullScreenLayout.jsx";
import FavIconLogo from "../assets/images/defaults/iapicon.png";

//Component imports
import VerifyOid from "./components/verifyOid/index.jsx";
import Login from "./components/login/index.jsx";
import Main from "./components/main/index.jsx";


const USER_CONTEXT_PERSISTENT = "USER_CONTEXT";

const RoutesLogin = [
    {   
        NAME: "Login",
        PATH: "/login",
        LAYOUT: FullScreenLayout,
        COMPONENT: Login
    },
    {   
        NAME: "Main",
        PATH: "/main",
        LAYOUT: FullScreenLayout,
        COMPONENT: Main
    },
    // {
    //     NAME: "ELECTRIC DATA",
    //     PATH: "/electric",
    //     LAYOUT: PanelLayout,
    //     COMPONENT: ElectricPanel
    // },
];

const App = () => {
    const [userContext, setUserContext] = useLocalStorage(USER_CONTEXT_PERSISTENT, null);

    const GetPageRoutes = () => {
        return(
            RoutesLogin.map((item) => {
                return (
                    <Route key={item.NAME} 
                        path = {item.PATH}
                        component = {() => <item.LAYOUT name={item.NAME} component={item.COMPONENT} /> } />
                )
            })
        );
    }

    return (
        <HashRouter>
            <Provider store={store}>
                <UserContext.Provider value={userContext}>
                <Favicon url={FavIconLogo} />
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path= "/verify/:oid" render={(props) =>
                        {
                            let propData = {
                                ...props,
                                setUserContext: setUserContext
                            };
                            return(
                              <VerifyOid {...propData} />
                            );
                        }
                        }/>
                        {GetPageRoutes()}
                    </Routes>
                </UserContext.Provider>
            </Provider>
        </HashRouter>
    );
}

ReactDOM.render(<App/>,document.getElementById('root'));
