//Config for available languages
export const LANGUAGES = ["English", "Japanese", "Chinese"];

//Config for available roles -> For Toyobijin
export const ROLE = ["viewer", "admin", "superUser"];

//Config for cookie auth key where sessoin will be stored
export const COOKIE_AUTH_KEY = "COOKIE_AUTH_KEY";

//export const MAP_TILE_SERVER = "http://tsunoshima-edge-dev.japaneast.cloudapp.azure.com:8181/styles/34e47b93-9e55-4c2e-b142-4994aee7e28f/{z}/{x}/{y}.png"
// export const MAP_TILE_SERVER = "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"

// Limited 1 file size.
// image file->1MB
// mp4 file->20MB
// PDF file->2MB

// -Limited 1 Tree total file size.
// 96MB
export const IMAGE_LIMIT = 5 * 1024 * 1024;
export const VIDEO_LIMIT = 20 * 1024 * 1024;
export const PDF_LIMIT = 20 * 1024 * 1024;
export const TOTAL_LIMIT = 96 * 1024 * 1024;


export default {};
