import { enableES5 } from 'immer';

import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";

// NOTE
// https://github.com/reduxjs/redux-toolkit/issues/476
// npm install immer@8.0.1
enableES5();

// this setting for unserializable object
const middleware = getDefaultMiddleware({ serializableCheck: false });

export default configureStore({
  reducer: {

  },
  middleware,
});