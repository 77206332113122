import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import homeLogo from "../../../assets/images/defaults/iapilogo.png";
import background from "../../../assets/images/defaults/strait.jpg";
import infoceanus from "../../../assets/images/defaults/infoceanus.png";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {BACKEND_URL} from "../../constants/request.js";

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    backgroundSize: "cover",
    padding: "0 5rem",
  },
  header: {
    width: "100%",
    display: "flex",
    padding: "2.5rem 0",
    fontSize: "2rem",
    borderBottom: "1px solid lightGray",
  },
  loginWindow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    marginTop: "3rem",
    padding: "40px 20px",
  },
  windowFields: {
    marginBottom: "3rem",
    fontSize: "16px",
  },
  windowItems: {
    width: "580px",
    height: "56px",
  },
  loginButton: {
    width: "580px",
    height: "56px",
    background: "#3EB3BC",
  },
  forgetPass: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  inqsq: {
    fontSize: "14px",
    margin: "20px 0 0",
    padding: "24px 25px 18px 44px",
    whiteSpace: "pre-line",
    borderRadius: "5px",
    backgroundColor: "rgba( 0, 35, 77, 0.8 )",
    color: "white",
    borderRadius: "5px",
  },
  inqsqbtn: {
    marginTop: "10px",
    whiteSpace: "pre-line",
    backgroundColor: "#9b0bab",
    color: "white",
    width: "200px",
    "&:hover": {
      background: "rgba( 0, 0, 0, 0.8 )",
      filter: "brightness(1.2)",
    },
    textTransform: "none",
    borderRadius: "5px",
  },
  inqsqbtnc: {
    marginTop: "10px",
    whiteSpace: "pre-line",
    backgroundColor: "rgba( 0, 0, 0, 0.8 )",
    color: "white",
    height: "auto",
    width: "200px",
    "&:hover": {
      background: "rgba( 0, 0, 0, 0.8 )",
      filter: "brightness(1.2)",
    },
    textTransform: "none",
  },
  infoceanus: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  infoceanusimg: {
    display: "flex",
    flexDirection: "column",
    width: "301px",
    height: "auto",
    alignItems: "flex-end",
    paddingTop: "20px"
  },
  inqsqicon: {
    whiteSpace: "pre-line",
    color: "white",
    display: "inline-flex",
    verticalAlign: "middle",
  },
  backlink: {
    display: "block",
    textDecoration: "none",
    color: "#fff",
    padding: "0",
    "&:hover": {
      background: "rgba( 0, 0, 0, 0.8 )",
      filter: "brightness(1.2)",
    },
  },
  welcome: {
    fontSize: "26px",
    whiteSpace: "pre-line",
    color: "white",
  },
});

const Login = (props) => {
  const onClickLogin = () => {
    window.location.href = `${BACKEND_URL}/auth/login`;
  }

  const { classes } = props;
  const homeBg = {
    maxWidth:"100%",
    maxHeight: "100%",
    backgroundImage: `url(${background})`,
  };

  return (
    <div className="home_page" style={homeBg}>
      <div className="homeLogo">
        <img src={homeLogo} />
        <div className={classes.inqsq}>
          <div className={classes.welcome}>{"Welcome to iSwagger"}</div>
          <label className="photo-file">
            <Button
              className={classes.inqsqbtn}
              component="div"
              onClick={onClickLogin}
            >
              &nbsp;サインイン / Sign in
            </Button>
          </label>
        <div className={classes.infoceanus}>
        <a target="_blank" className={classes.infoceanusimg} href="https://infoceanus.com" rel="noreferrer">
          <img src={infoceanus}/>
        </a>
        </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default withStyles(styles)(Login);
