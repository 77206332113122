import React from "react";
import {LoginOid, LoginValidateToken} from "../../api/auth.js";
import { Navigate } from "react-router-dom";
import {COOKIE_AUTH_KEY} from "../../constants/index.js";
import Cookies from "universal-cookie";

const VerifyOid = (props) => {
    const [redirect, setRedirect] = React.useState(<div></div>);

    React.useEffect(() => {
        async function fetchData()
        {
            let userToken = await LoginOid(props.match.params.oid);
            if (userToken.isLoginSuccess === true) {
                let cookies = new Cookies();
                cookies.set(COOKIE_AUTH_KEY, userToken.token, {path: '/'});

                let userData = await LoginValidateToken();

                if (userData.isValid){
                    props.setUserContext(userData.userData);
                    setRedirect(<Navigate to="/main" replace />);
                } else {
                    alert("Invalid authentication");
                }

            }else {
                alert("No info on database");
            }
        }
        fetchData();
    }, []);

    return(
        redirect
    );
  }

export default VerifyOid;